<template>
  <div class="row">
    <templates-picker
      v-if="!selectedWaTemplate"
      :inbox-id="inboxId"
      @onSelect="pickTemplate"
    />
    <template-parser
      v-else
      :template="selectedWaTemplate"
      @resetTemplate="onResetTemplate"
      @sendMessage="onSendMessage"
    />
  </div>
</template>

<script>
import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker.vue';
import TemplateParser from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplateParser.vue';
export default {
  components: {
    TemplatesPicker,
    TemplateParser,
  },
  props: {
    inboxId: {
      type: Number,
      default: undefined,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedWaTemplate: null,
    };
  },
  methods: {
    pickTemplate(template) {
      // @TODO remover em outubro
      if (template?.category === 'MARKETING') {
        window.alert(
          'Atenção\n\nTemplates classificados como marketing podem ser utilizados apenas em 𝗰𝗮𝗺𝗽𝗮𝗻𝗵𝗮𝘀 𝗱𝗲 𝗱𝗶𝘀𝗽𝗮𝗿𝗼 𝗲𝗺 𝗺𝗮𝘀𝘀𝗮.\n\nA partir de 𝟭𝟰/𝟭𝟬, não será mais possível utilizar templates categorizados como marketing nesta tela. Para continuar se comunicando com seus clientes, converta seu template para a categoria 𝘂𝘁𝗶𝗹𝗶𝗱𝗮𝗱𝗲𝘀.'
        );
      }
      this.$emit('pickTemplate', true);
      this.selectedWaTemplate = template;
    },
    onResetTemplate() {
      this.$emit('pickTemplate', false);
      this.selectedWaTemplate = null;
    },
    onSendMessage(message) {
      this.$emit('on-send', message);
    },
    onClose() {
      this.$emit('cancel');
    },
  },
};
</script>

<style></style>
